<template>
  <b-overlay :show="loadingOverlay">
  <div v-if="portfolio !== null">
    <h1 class="mb-2">ساخت نمونه کار</h1>
    <b-card>
      <b-row>
        <!-- Field: name -->
        <b-col
            cols="12"
            md="12"
        >
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group
                  label="موضوع"
                  label-for="name"
              >
                <b-form-input
                    id="name"
                    v-model="portfolio.title"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!-- Field: description -->
        <b-col
            cols="12"
            md="6"
        >
          <b-form-group
              label="توضیحات"
              label-for="schema"
          >
            <textarea class="form-control" v-model="portfolio.description"></textarea>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="پیوست عکس"
                  label-for="mc-country"
              >
                <b-form-file

                    accept=".jpg, .png, .gif"
                    placeholder="انتخاب عکس"
                    @input="makeBase64Pic"
                    ref="refInputEl"
                    drop-placeholder="Drop file here..."
                    multiple
                />
              </b-form-group>

            </b-col>
            <b-col v-if="portfolio.length>0" class="my-3" cols="12">
              <div class="d-flex align-items-center justify-content-start  flex-wrap">
                <div v-for="(item,idx) in portfolio.files" :key="idx" class="position-relative mx-2 bg-white rounded">
                  <feather-icon @click="deletePhoto(item)" icon="TrashIcon" size="20"
                                class="text-danger position-absolute top-0 cursor-pointer"/>
                  <img :src="baseUrl +'media/gallery/portfolio/'+ item.pictureUrl" style="width: 10rem;height: 10rem;object-fit: contain" class="rounded" alt="">
                </div>

              </div>

            </b-col>
            <b-col class="my-3" cols="12">
              <b-button type="button" class="btn  " variant="primary" @click="updatePortfolio"> ثبت</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </b-card>
  </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BFormFile, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BForm, BModal, BFormTextarea, BOverlay
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {UpdatePortfolio,GetPortfolio,DeleteMediaByPicURL} from "@/libs/Api/portfolio";
import {getUserData} from "@/auth/utils";
import Helper from "@/libs/Helper";

export default {
  name: "PortfolioEdit",
  title: "ویرایش نمونه کار",
  data() {
    return {
      loadingOverlay:false,
      portfolio:null,
      baseUrl:Helper.baseUrl
    }
  },
  async mounted(){
    await this.GetPortfolio();
  },
  methods: {
    async deletePhoto(item){
    let _this = this
      _this.loadingOverlay = true
      let deleteMediaByPicURL = new DeleteMediaByPicURL(_this)
      deleteMediaByPicURL.setParams([item.pictureUrl])
      await  deleteMediaByPicURL.fetch((content)=>{
          _this.GetPortfolio();
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عکس انتخاب شده با موفقیت حذف شد`,
          },
        })
      },(err)=>{
        console.log(err)
      })
      _this.loadingOverlay = false

    },
    async GetPortfolio(){
      let _this = this
      _this.loadingOverlay = true
      let getPortfolio = new GetPortfolio(_this)
      getPortfolio.setParams({id:_this.$route.params.id})
      await getPortfolio.fetch((content)=>{
        _this.portfolio = content
      },(err)=>{
        console.log(err)
      })
      _this.loadingOverlay = false
    },
    async updatePortfolio() {
      let _this = this;
      if (_this.portfolio.title === '' || _this.portfolio.description === '') {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertIcon',
            variant: 'danger',
            text: `لطفا همه فیلد هارا تکمیل کنید`,
          },
        })
      } else {
        _this.portfolio.files.forEach((item) => {
          delete item.blob
        })
        let updatePortfolio = new UpdatePortfolio(_this);
        updatePortfolio.setParams(_this.portfolio);
        await updatePortfolio.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `نمونه کار بروزرسانی شد.`,
            },
          })
          _this.$router.push("/pages/portfolio/PortfolioList");
        }, function (error) {
          console.log(error)
        })
      }
    },
    async makeBase64Pic() {
      const that = this
      const f = []
      let tmpImage = {
        blob: '',
        base64: '',
        pictureUrl: '',
        priority: 1
      }
      if (this.$refs.refInputEl.files.length > 4 || this.portfolio.files.length > 4) {
        this.$toast.error('میتوانید تا 5 عکس را همزمان اضافه کنید')
      } else {
        Array.prototype.forEach.call(this.$refs.refInputEl.files, (element) => {
          if (element.size > 512000) {
            this.$toast.error('اندازه عکس نمیتواند بیشتر از 512 کیلوبایت باشد')
          } else {
            f.push(element)
          }
        })
        f.forEach((element) => {
          const reader = new FileReader()
          reader.onload = (function (theFile) {
            return function () {
              let binaryData = reader.result
              tmpImage.base64 = window.btoa(binaryData)
              tmpImage.blob = URL.createObjectURL(element)
              const clone = {...tmpImage}
              that.portfolio.files.push(clone)
              tmpImage.blob = ''
              tmpImage.priority = 2
              tmpImage.base64 = ''
            }
          })(f)
          reader.readAsBinaryString(element)
        })

      }
      this.$refs.refInputEl.reset()
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BPagination,
    BModal,
    BFormTextarea,
    vSelect,
    BOverlay
  }
}
</script>

<style>

</style>
